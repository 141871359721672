import { v4 as uuidv4 } from 'uuid';
import { uploadData, isCancelError } from 'aws-amplify/storage';
import Api from '../../api/Api'
import { noop } from 'lodash';

export default {
  namespaced: true,
  state: {
    files: [],
  },

  getters: {
    statuses: function () {
      return {
        ADDED: 1,
        WAITING: 2,
        UPLOADING: 3,
        FINISHED: 4,
        ERROR: 5,
      };
    },
    files: function(state) {
      return state.files;
    },
  },

  mutations: {
    addFile: function (state, file) {
      state.files.push(file);
    },
    toggleTeamonly: function(state, file) {
      let index = state.files.indexOf(file);
      state.files[index].teamonly = !state.files[index].teamonly;
    },
    setNote: function(state, payload) {
      let index = state.files.indexOf(payload.file);
      state.files[index].note = payload.note;
    },
    deleteFile: function(state, file) {
      let index = state.files.indexOf(file);
      state.files.splice(index, 1);
    },
    updateProgress: function(state, payload) {
      let progress = payload.progress;
      let index = state.files.indexOf(payload.file);

      state.files[index].progress.percentage = parseInt(progress.transferredBytes / progress.totalBytes * 100);
      state.files[index].progress.uploaded = progress.transferredBytes;
    },
    setStatusWaiting: function(state, file) {
      let index = state.files.indexOf(file);
      state.files[index].status = 2;
    },
    setStatusUploading: function(state, file) {
      let index = state.files.indexOf(file);
      state.files[index].status = 3;
    },
    setStatusFinished: function(state, file) {
      let index = state.files.indexOf(file);
      state.files[index].status = 4;
    },
    setStatusError: function(state, payload) {
      let index = state.files.indexOf(payload.file);
      state.files[index].status = 5;
      state.files[index].errorMessage = payload.errorMessage;
    },
    setNodeID: function(state, payload) {
      let index = state.files.indexOf(payload.file);
      state.files[index].meta.nodeid = payload.nodeid;
    },
    setPodID: function(state, payload) {
      let index = state.files.indexOf(payload.file);
      state.files[index].meta.podid = payload.podid;
    },
    setAssetType: function(state, payload) {
      for (var i = 0; i < state.files.length; i++) {
        if (state.files[i].meta.fileuuid == payload.fileuuid) {
          state.files[i].meta.assettype = payload.value;
        }
      }
    },
    setAssetTypes: function(state, payload) {
      const fileTypes = payload.fileTypes;

      for (let i = 0; i < state.files.length; i++) {
        if (fileTypes.has(state.files[i].meta.fileuuid)) {
          state.files[i].meta.assettype = fileTypes.get(state.files[i].meta.fileuuid);
        } else {
          state.files[i].meta.assettype = 12; // Other
        }
      }
    },
    resetFiles: function(state) {
      state.files = [];
    },
    setCancelUploadFunction: function(state, payload) {
      const index = state.files.indexOf(payload.file);
      state.files[index].cancelUpload = payload.fn;
    }
  },

  actions: {
    addFiles: function(context, payload) {
      const USER = context.rootGetters.keywords.AUTH.GETUSER;
      const GETNODEBYID = context.rootGetters.keywords.NODE.GETNODEBYID;
      const USERUUID = context.rootGetters[USER].userId;

      for (let i = 0; i < payload.fileList.length; i++) {
        let data = {
          file: payload.fileList[i],
          podid: payload.podID,
          status: context.getters.statuses.ADDED,
          note: '',
          teamonly: false,
          errorMessage: '',
          cancelUpload: noop,
          progress: {
            percentage: 0,
            uploaded: 0,
          },
          meta: {
            fileuuid: uuidv4(),
            filename: payload.fileList[i].name,
            filesize: payload.fileList[i].size,
            useruuid: USERUUID,
            podid: payload.podID,
            webkitRelativePath: payload.fileList[i].webkitRelativePath || "",
            nodeid: payload.nodeID,
            nodename: context.rootGetters[GETNODEBYID](payload.nodeID).name,
            // nodeid - added later
            // assettype - eventually added later
          },
        };

        context.commit('addFile', data);
      }
    },
    setAssetType: function(context, payload) {
      context.commit('setAssetType', payload);
    },
    toggleTeamonly: function(context, file) {
      context.commit('toggleTeamonly', file);
    },
    setNote: function(context, payload) {
      context.commit('setNote', payload);
    },
    removeFile: function(context, file) {
      context.commit('deleteFile', file);
    },
    uploadFile: async function(context, waitingFilePayload) {
      const { file, labels, priorities } = waitingFilePayload;

      if (file.meta.webkitRelativePath
        && typeof file.meta.webkitRelativePath === 'string'
        && file.meta.webkitRelativePath.length > 0
      ) {
        let isAnySegmentTooLong = false;
        const maxLength = 100;

        const segments = file.meta.webkitRelativePath.split('/');

        let segment = '';
        for (segment of segments) {
          if (segment.length > maxLength) {
            isAnySegmentTooLong = true;
            break;
          }
        }

        if (isAnySegmentTooLong) {
          let payload = {
            file: file,
            errorMessage: `The folder name "${segment}" is too long. Please shorten it to ${maxLength} characters or less.`,
          }

          context.commit('setStatusError', payload);
          return;
        }
      }

      context.commit('setStatusUploading', file);

      const CURRENTNODE = context.rootGetters.keywords.NODE.CURRENTNODE;
      const node = context.rootGetters[CURRENTNODE];

      try {
        if (!node && !file.meta.nodeid) {
          throw "menus.uploadFiles.activeNodeRequired";
        }

        if (!file.meta.nodeid) {
          context.commit('setNodeID', {file, nodeid: node.id});
        }

        let podData = {
          uuid: file.meta.fileuuid,
          filename: file.meta.filename,
          filesize: file.meta.filesize,
          useruuid: file.meta.useruuid,
          teamonly: file.teamonly,
          note: file.note,
          nodeid: file.meta.nodeid,
          webkitRelativePath: file.meta.webkitRelativePath,
          labels,
        };

        console.log('podData', podData);

        const nodeId = file.meta.nodeid;

        let VERB = context.rootGetters.keywords.POD.CREATEPOD;

        if (file.meta.podid) {
          podData.podid = file.meta.podid;
          delete podData.nodeid;
          delete podData.teamonly;
          delete podData.note;
          delete file.meta.nodeid;
          VERB = context.rootGetters.keywords.POD.CREATEPODASSET;

          if (file.meta.assettype) {
            podData.assettype = file.meta.assettype;
          }
        }

        let res = await Api.service.checkResource('storage', podData.filesize, nodeId);
        if (res && res.data && res.data.willExceedLimit) {
          throw "menus.uploadFiles.insufficientStorage"
        }

        res = await context.dispatch(VERB, podData, { root: true });

        // Sanity check: the upload might have been cancelled whilst we've been creating the pod. In this case
        // we should remove it altogether and move along with the next one.
        const fileStillExists = context.state.files.indexOf(file) >= 0;

        if (VERB === context.rootGetters.keywords.POD.CREATEPOD && !fileStillExists) {
          const DELETEPODS = context.rootGetters.keywords.POD.DELETEPODS;
          await context.dispatch(DELETEPODS, [ res.id ], { root: true });
          return;
        }

        const options = {
          // level: 'public',
          metadata: JSON.parse(JSON.stringify(file.meta)),   // deep copy
          onProgress: function(progress) {
            let payload = { file, progress };
            context.commit('updateProgress', payload)
          }
        };

        options.metadata.filesize = file.meta.filesize.toString();
        options.metadata.filename = encodeURIComponent(file.meta.filename);
        options.metadata.nodename = encodeURIComponent(file.meta.nodename);
        options.metadata.webkitRelativePath = encodeURIComponent(file.meta.webkitRelativePath);
        options.metadata.priority = (Array.isArray(priorities) && priorities.includes(file.meta.fileuuid)) ? 1 : 0;

        if (file.meta.nodeid) {
          options.metadata.nodeid = file.meta.nodeid.toString();
        }

        if (options.metadata.podid) {
          options.metadata.podid = file.meta.podid.toString();
          delete options.metadata.nodeid;

          if (file.meta.assettype) {
            options.metadata.assettype = file.meta.assettype.toString();
          }
        } else {
          delete options.metadata.podid;
        }

        if (VERB === context.rootGetters.keywords.POD.CREATEPOD && fileStillExists) {
          context.commit('setPodID', { file, podid: res.id });
        }

        const operation = await uploadData({
          path: `public/${file.meta.fileuuid}`,
          data: file.file,
          options: options,
        });

        context.commit('setCancelUploadFunction', { file, fn: operation.cancel });

        await operation.result;

        context.commit('setStatusFinished', file);
      } catch (e) {
        if (isCancelError(e)) {
          // Disregard errors, that were raised due to manual cancellation.
          return;
        }

        let payload = {
          file: file,
          errorMessage: typeof e == 'string' ? e : '',
        }

        console.error("Error uploading file:", e);
        context.commit('setStatusError', payload);
      }
    },
    uploadAllFiles: async function(context, payload) {
      const CURRENTNODE = context.rootGetters.keywords.NODE.CURRENTNODE;
      const node = context.rootGetters[CURRENTNODE];

      if (!node) {
        throw "menus.uploadFiles.activeNodeRequired";
      }

      for (let file of context.state.files) {
        if (file.status === 1) {
          context.commit('setStatusWaiting', file);

          if (!file.meta.nodeid) {
            context.commit('setNodeID', {file, nodeid: node.id});
          }
        }
      }

      // Prevent user leave page
      if (!window.onbeforeunload) {
        // NOTE: On most modern browsers the message is overrided by the browser.
        window.onbeforeunload = function() { return "Leaving this page will cancel the current uploads. Are you sure?" };
        window.timesUploading = 1;
      } else {
        window.timesUploading++;
      }

      // get any file of status waiting
      let waitingFile = context.state.files.find(x => x.status === 2);
      while (waitingFile) {
        const waitingFilePayload = {...payload, file: waitingFile};

        await context.dispatch('uploadFile', waitingFilePayload);
        waitingFile = context.state.files.find(x => x.status === 2);
      }

      // Remove prevention
      if (window.timesUploading == 1) {
        window.onbeforeunload = null;
        delete window.timesUploading;
      } else {
        window.timesUploading--;
      }

    },
    cancelUpload: async function(context, file) {
      const index = context.state.files.indexOf(file);

      if (index >= 0) {
        const record = context.state.files[index];
        context.commit('deleteFile', record);

        if (record.status === context.getters.statuses.UPLOADING || record.status === context.getters.statuses.WAITING) {
          await record.cancelUpload();

          if (record.meta.podid) {
            const DELETEPODS = context.rootGetters.keywords.POD.DELETEPODS;
            await context.dispatch(DELETEPODS, [ record.meta.podid ], { root: true });
          }
        }
      }
    },
    cancelUploads: async function(context) {
      const cancelUploadOfFiles = [];

      for (const file of context.state.files) {
        if (file.status !== context.getters.statuses.FINISHED && file.status !== context.getters.statuses.ERROR) {
          cancelUploadOfFiles.push(file);
        }
      }

      if (cancelUploadOfFiles.length > 0) {
        for (const file of cancelUploadOfFiles) {
          context.commit('deleteFile', file);
        }

        await Promise.all(cancelUploadOfFiles.map((file) => file.cancelUpload()));
        const DELETEPODS = context.rootGetters.keywords.POD.DELETEPODS;
        await context.dispatch(
          DELETEPODS,
          cancelUploadOfFiles.filter((file) => file.meta.podid !== null).map((file) => file.meta.podid),
          { root: true }
        );

        window.onbeforeunload = null;
      }
    }
  }
}
