<template>
  <v-menu
    v-model="contextMenuModel"
    :position-x="positionX"
    :position-y="positionY"
    absolute
    offset-y
  >
    <v-list v-if="node" style="overflow-y:auto;" data-cy="nodeSideMenu-treeItemContext-list">

      <CreateNodeDialog v-if="showingCreateClient" :nodeType="nodeTypes.CLIENT" :parentNode="node">
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-createClient">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-folder-plus</v-icon>
                {{$t('menus.createNode.createClient')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </CreateNodeDialog>

      <CreateNodeDialog v-if="showingCreateProject" :nodeType="nodeTypes.PROJECT" :parentNode="node">
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-createProject">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-folder-plus</v-icon>
                {{$t('menus.createNode.createProject')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </CreateNodeDialog>

      <CreateNodeDialog v-if="showingCreateFolder" :nodeType="nodeTypes.FOLDER" :parentNode="node">
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-createFolder">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-folder-plus</v-icon>
                {{$t('menus.createNode.createFolder')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </CreateNodeDialog>

      <v-divider v-if="showingCreateClient || showingCreateProject || showingCreateFolder">
      </v-divider>

      <CreateNodeDialog v-if="$canControlNodeAndPod()" :editNodeId="node.id" :nodeType="node.type">
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-updateNode">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-folder-edit-outline</v-icon>
                {{$t('menus.createNode.editFolder')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </CreateNodeDialog>

      <DownloadDialog  v-if="!$isAdobeExtension() && !$isFCPExtension() && $canDownloadNodeAndPod()" v-bind:nodes="Array(node)">
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-downloadSelected">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-folder-download-outline</v-icon>
                {{$t('routes.node.downloadNode')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </DownloadDialog>

      <UploadFilesDialog v-if="$canUploadFileAndAsset()" :node="node">
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-uploadFiles">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-upload</v-icon>
                {{$t('menus.tools.uploadFiles')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </UploadFilesDialog>

      <v-divider></v-divider>

      <CopySelectedDialog v-if="$canControlNodeAndPod()" :nodes="Array(node)" :pods="Array()">
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-copySelected">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-checkbox-multiple-marked-outline</v-icon>
                {{$t('routes.node.copyNode')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </CopySelectedDialog>

      <MoveSelectedDialog v-if="$canControlNodeAndPod()" :nodes="Array(node)" :pods="Array()">
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-moveSelected">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-checkbox-multiple-marked</v-icon>
                {{$t('routes.node.moveNode')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </MoveSelectedDialog>

      <DeleteNodeDialog v-if="$canControlNodeAndPod() && !isCurrentNodeTenant" v-bind:node="node">
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-deleteNode">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-trash-can-outline</v-icon>
                {{$t('menus.tools.deleteNode')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </DeleteNodeDialog>

      <v-divider></v-divider>

      <!-- <v-list-item @click="stub">
          <v-list-item-content>
            <v-list-item-title>
              Set team only
            </v-list-item-title>
          </v-list-item-content>
      </v-list-item> -->

      <ArchiveSelectedDialog
        v-if="!node.archived && $canControlNodeAndPod() && $canArchiveNodeAndPod()"
        v-bind:action="'archive'"
        v-bind:nodes="Array(node)"
        v-bind:pods="Array()"
      >
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-archiveSelected">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-archive-arrow-down-outline</v-icon>
                {{$t('menus.tools.archiveSelected')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </ArchiveSelectedDialog>

      <ArchiveSelectedDialog
        v-if="node.archived && $canControlNodeAndPod() && $canArchiveNodeAndPod()"
        v-bind:action="'unarchive'"
        v-bind:nodes="Array(node)"
        v-bind:pods="Array()"
      >
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-unarchiveSelected">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-archive-arrow-up-outline</v-icon>
                {{$t('menus.tools.unarchiveSelected')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </ArchiveSelectedDialog>

    </v-list>
  </v-menu>
</template>

<script>
import CreateNodeDialog from '../../../../layouts2/mainLayout/components/nodeSidemenu/components/dialogs/CreateNodeDialog.vue'
import DeleteNodeDialog from '../../../../layouts2/mainLayout/components/nodeSidemenu/components/dialogs/DeleteNodeDialog.vue'

import DownloadDialog from '../dialogs/DownloadDialog.vue'
import UploadFilesDialog from '../dialogs/UploadFilesDialog.vue'
import MoveSelectedDialog from '../dialogs/MoveSelectedDialog.vue'
import CopySelectedDialog from '../dialogs/CopySelectedDialog.vue'
import ArchiveSelectedDialog from '../dialogs/ArchiveSelectedDialog.vue'

export default {
  name: "NodeContextMenu",
  components: {
    DownloadDialog,
    CreateNodeDialog,
    DeleteNodeDialog,
    UploadFilesDialog,
    MoveSelectedDialog,
    CopySelectedDialog,
    ArchiveSelectedDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    positionX: {
      type: Number,
      required: true,
    },
    positionY: {
      type: Number,
      required: true,
    },
    node: {
      type: Object,
      // required: true,
    },
  },
  data() {
    return {};
  },
  watch: {
    nodeFromState() {
      if (this.nodeFromState && this.nodeFromState.archived !== this.archiveStatus.NOTARCHIVED) {
        this.contextMenuModel = false;
      }
    },
  },
  methods: {
    stub() {
      // console.log("Click on context menu!");
      this.$emit('input', false);
    },
  },
  computed: {
    contextMenuModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    // currentNode: function() {
    //   const CURRENTNODE = this.$store.getters.keywords.NODE.CURRENTNODE;
    //   return this.$store.getters[CURRENTNODE]
    // },
    nodeTypes: function() {
      const NODETYPES = this.$store.getters.keywords.COMMON.NODETYPES;
      return this.$store.getters[NODETYPES];
    },
    rootFolderId: function() {
      const ROOTFOLDERID = this.$store.getters.keywords.COMMON.ROOTFOLDERID;
      return this.$store.getters[ROOTFOLDERID];
    },
    archiveStatus() {
      const ARCHIVESTATUS = this.$store.getters.keywords.COMMON.ARCHIVESTATUS;
      return this.$store.getters[ARCHIVESTATUS];
    },
    nodeFromState() {
      if (!this.node || !this.node.id) {
        return null;
      }

      // This is the node from the state manager.
      // The node from the props is from event and does not have watch events.
      const GETNODEBYID = this.$store.getters.keywords.NODE.GETNODEBYID;
      return this.$store.getters[GETNODEBYID](this.node.id);
    },
    showingCreateFolder() {
      return this.$canControlNodeAndPod();
    },
    showingCreateProject() {
      // return  this.$canControlNodeAndPod() &&
      //         this.node &&
      //         (this.node.type == this.nodeTypes.CLIENT ||
      //         this.node.type == this.nodeTypes.TENANT);
      return  this.$canControlNodeAndPod();
    },
    showingCreateClient() {
      return  this.$canControlNodeAndPod() &&
              this.node &&
              this.node.type == this.nodeTypes.TENANT;
    },
    isCurrentNodeTenant: function() {
      // return this.node.type === this.nodeTypes.TENANT;
      return this.node.parentid === this.rootFolderId && this.node.type === this.nodeTypes.TENANT;
    },
    currentNodeId: function() {
      const GETCURRENTNODEID = this.$store.getters.keywords.NODE.GETCURRENTNODEID;
      return this.$store.getters[GETCURRENTNODEID]
    },
    hasSelectedItems: function() {
      const HASSELECTEDNODES = this.$store.getters.keywords.NODE.HASSELECTEDNODES;
      const HASSELECTEDPODS = this.$store.getters.keywords.POD.HASSELECTEDPODS;

      return this.$store.getters[HASSELECTEDNODES] || this.$store.getters[HASSELECTEDPODS];
    },
  }
}
</script>
