<template>
  <div v-show="getAccessPoints.length > 1">
    <v-menu
    v-model="isMenuVisible"
    :close-on-click="true"
    :close-on-content-click="false"
    :style="{'height': '100vh', 'overflow-y': 'auto'}"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn

          v-bind="attrs"
          v-on="on"
          color="primary"
          elevation="0"
          block
          rounded
        >
          <v-icon>
            mdi-home-circle
          </v-icon>

          <span v-if="getActiveAP">
            {{getActiveAP.node.name}}
          </span>
        </v-btn>
      </template>

      <v-list  class="overflow-y-auto" style="max-height: 80vh;">
        <v-list-group
        v-for="tenant in getTenants"
        :key="tenant.id"
        no-action
        @click="listGroupClick"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="tenant.name"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
          v-for="accesspoint in tenant.accessPoints"
          :key="accesspoint.node.id"
          :disabled="accesspoint.node.id == getActiveAP?.node?.id"
          @click="setActiveAP(tenant, accesspoint)"
          class="pl-8"
          >
            <v-list-item-content>
              <v-list-item-title v-text="accesspoint.node.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default {
  name: "AccesspointSwitch",
  data() {
    return {
      isMenuVisible: false,
    };
  },
  watch: {
    getActiveAP(accesspoint) {
      if (accesspoint) {
        this.$updateAbilities(accesspoint.group.abilities);
      } else {
        this.$updateAbilities([]);
      }

      if (this.$route.path.startsWith('/console')) {
        if (accesspoint) {
          const SETACTIVENODE = this.$store.getters.keywords.NODE.SETACTIVENODE;
          this.$store.dispatch(SETACTIVENODE, accesspoint.node.id);
        }

        const RESETUSERS = this.$store.getters.keywords.USER.RESETUSERS;
        this.$store.commit(RESETUSERS);

        if (this.$canListUser()) {
          const REFRESHUSERS = this.$store.getters.keywords.USER.REFRESHUSERS;
          this.$store.dispatch(REFRESHUSERS, { accessPointId: accesspoint.node.id });
        }
      }
    }
  },
  methods: {
    setActiveAP(tenant, accesspoint) {
      const SETROOTNODEID = this.$store.getters.keywords.AUTH.SETROOTNODEID;
      this.$store.dispatch(SETROOTNODEID, accesspoint.node.id)

      this.isMenuVisible = false;

      if (this.$route.path.startsWith('/console')) {
        if (this.$route.path !== `/console/index`) {
          this.$router.push(`/console/index`);
        }

        return;
      }

      this.$router.push(`/node/${accesspoint.node.id}`);
    },
    listGroupClick() {
      this.$nextTick(function() {
        window.dispatchEvent(new Event('resize'))
      });
    }
  },
  computed: {
    getTenants() {
      const GETTENANTS = this.$store.getters.keywords.AUTH.GETTENANTS;
      return this.$store.getters[GETTENANTS];
    },
    getActiveAP() {
      const GETACTIVEACCESSPOINT = this.$store.getters.keywords.AUTH.GETACTIVEACCESSPOINT;
      return this.$store.getters[GETACTIVEACCESSPOINT];
    },
    getAccessPoints() {
      const GETACCESSPOINTS = this.$store.getters.keywords.AUTH.GETACCESSPOINTS;
      return this.$store.getters[GETACCESSPOINTS];
    },
  },
}
</script>

<style>
</style>
