import API from '../AxiosInstance';

export default {
  getAccessPoints: function() {
    const body = {
      // headers, body parameters, query parameters
    };

    return API.get('nodes', body);
  },

  getNodeByID: function(id) {
    const body = {
        // headers, body parameters, query parameters
    };
    return API.get('nodes/' + id, body);
  },

  createNode: function(params) {
    let formData = new FormData();

    for ( var key in params ) {
      formData.append(key, params[key]);
    }

    const options = {
      // headers, body parameters, query parameters
      headers: {
        "Content-Type": "multipart/form-data; boundary=file",
      },
    };
    return API.post('nodes', formData,  options);
  },

  updateNode: function(params, id) {
    let formData = new FormData();

    for (const key in params ) {
      //This is done because FormData casts null to the string "null", better to not send key-value pair in this case
      if (params[key] !== null) {
        formData.append(key, params[key]);
      }
    }

    params.id = id;
    const options = {
      headers: {
        "Content-Type": "multipart/form-data; boundary=file",
      },
    };
    return API.patch('nodes/' + id, formData, options);
  },

  updateFolder: function(id, data) {
    const body = data;
    return API.patch('nodes/' + id + '/folders', body);
  },

  deleteNode: function(id) {
    const body = {};
    return API.delete('nodes/' + id, body);
  },

  deleteLogo: function(id) {
    const body = {};
    return API.delete('/nodes/'+id+'/logo', body);
  },

  softDeleteTenant: function(id) {
    const body = {};
    return API.delete('tenants/' + id + '/soft', body);
  },

  getUsers: function(nodeID) {
    return API.get('nodes/' + nodeID + '/users/');
  },

  getSubnodes: function(nodeID, subnodesType) {
    let url = 'nodes/'+nodeID+'/subnodes';
    if (subnodesType !== null) {
      url += '/'+subnodesType;
    }

    const body = {};

    return API.get(url, body);
  },

  moveManyNodes: function(data) {
    const body = data;
    return API.patch('nodes/move', body);
  },

  copyManyNodes: function(data) {
    const body = data;
    return API.post('nodes/copy', body)
      .then((response) => {
        return response.data;
      });
  },

  updateNote: function(payload) {
    const body = {
      note: payload.note
    };

    return API.patch('nodes/' + payload.id + '/note', body);
  },

  updateProjectStatus: function(params, id) {
    const body = params;
    return API.patch('nodes/'+ id + '/projectstatus', body);
  },

  renameNode: function(params, id) {
    const body = {
        name: params.name,
    };

    return API.patch('nodes/' + id + '/name', body);
  },

  getProperties: function(nodeID) {
    const body = {};
    return API.get('nodes/' + nodeID + '/properties', body);
  }
};
