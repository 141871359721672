<template>
  <div>
    <div class="d-flex">
      <div class="flex-grow-1 d-flex align-center" data-cy="podFiles-mediaAssets-title">
        <h1>Media assets</h1>
      </div>

      <div v-if="$canUploadFileAndAsset() && isCompletedState && getCurrentNode" class="flex-grow-0">
        <UploadFilesDialog :node="getCurrentNode" :pod="pod">
          <v-btn color="primary" elevation=0 data-cy="podFiles-mediaAssets-uploadAsset-btn">
            <v-icon>mdi-plus-circle</v-icon>
            {{$t('routes.pod.menuTools.uploadAsset')}}
          </v-btn>
        </UploadFilesDialog>
      </div>
      <div v-if="isCompletedState">
        <v-menu offset-y v-model="assetMenuIsActive">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mt-2" data-cy="podFiles-mediaAssets-assetMenu-btn">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-if="!$isAdobeExtension() && !$isFCPExtension()" @click="downloadAllAssets">
              <v-list-item-icon>
                <v-icon small>mdi-folder-arrow-down-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('routes.pod.downloadAllAssets') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="!$isAdobeExtension() && !$isFCPExtension()"
              @click="downloadSelectedAssets"
              :disabled="!hasSelectedAssets"
              v-show="hasSelectedAssets"
            >
              <v-list-item-icon>
                <v-icon small>mdi-file-download-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('routes.pod.downloadSelectedAssets') }}</v-list-item-title>
            </v-list-item>

            <DeleteAllAssetsDialog v-bind:pod="pod">
              <v-list-item @click="stub">
                <v-list-item-icon>
                  <v-icon small>mdi-delete-forever-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('routes.pod.deleteAllAssets') }}</v-list-item-title>
              </v-list-item>
            </DeleteAllAssetsDialog>

            <DeleteSelectedAssetsDialog v-bind:pod="pod" @assetsDeleted="closeMenu">
              <v-list-item @click="stub" :disabled="!hasSelectedAssets" v-show="hasSelectedAssets">
                <v-list-item-icon>
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('routes.pod.deleteSelectedAssets') }}</v-list-item-title>
              </v-list-item>
            </DeleteSelectedAssetsDialog>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div>
      <div v-for="item in items" :key="item.id">
        <div class="my-2 text-center">
          <h3>{{$t(item.name)}}</h3>
        </div>

        <div
        v-for="asset in item.children"
        :key="asset.id"
        v-on:click.ctrl.prevent="toggleAssetSelection(asset)"
        v-on:click.shift.prevent="toggleAssetSelection(asset)"
        v-on:contextmenu.stop.prevent="contextMenuClick($event, asset)"
        class="prevent-select mt-1 d-flex align-center pod-asset-list-item"
        style=""
        >
          <div v-if="asset.uuid" class="d-flex align-center justify-center flex-grow-0">
            <v-checkbox
                v-bind:input-value="isCheckboxSelected(asset)"
                v-on:click.prevent="toggleAssetSelection(asset)"
                class="ma-0 pa-0"
                data-cy="podFiles-mediaAssets-checkbox"
                hide-details
            ></v-checkbox>
          </div>

          <!-- Asset name -->
          <div class="flex-grow-1">

            <div class="d-flex">
              <div class="d-flex align-center mr-2">
                <v-icon v-if="asset.broken">
                  mdi-image-broken
                </v-icon>

                <v-tooltip v-if="!asset.broken && isSystemGeneratedFile(asset)" bottom z-index="1200">
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      v-bind="attrs" v-on="on"
                      :src="getSystemGeneratedIcon"
                      style="width: 20px; height: 20px;"
                    />
                  </template>
                  <span>{{$t('routes.pod.thisIsSystemFile')}}</span>
                </v-tooltip>
              </div>
              <div class="flex-grow-1" data-cy="podFiles-mediaAssets-assetName">
                <span style="word-wrap: anywhere; white-space: pre-line; font-weight: 600;">
                  {{asset.name}}
                </span>

                <!-- Status or clarification -->
                <div v-if="asset.clarification != null" data-cy="podFiles-mediaAssets-clarification">
                  <v-tooltip bottom z-index="1200">
                    <template v-slot:activator="{ on, attrs }">
                      <small v-bind="attrs" v-on="on">{{ assetState(asset) }}</small>
                    </template>
                    <span> {{ asset.clarification }} </span>
                  </v-tooltip>
                </div>
                <div v-else data-cy="podFiles-mediaAssets-state">
                  <small>{{ assetState(asset) }}</small>
                </div>

              </div>
            </div>

          </div>


          <!-- Size -->
          <div class="text-center">
            <small>{{ (asset && asset.size) ? asset.size : 0 | prettyBytes(2, false) }}</small>
            <br/>
            <!-- Mimetype -->
            <small>{{asset.mimetype}}</small>
          </div>


          <!-- Three-dot menu -->
          <div class="ml-2">
            <v-btn
            @click="contextMenuBtnClick($event, asset)"
            depressed
            small icon
            data-cy="podFiles-mediaAssets-actions-btn"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <AssetsContextMenu
      v-model="showingAssetContextMenu"
      :positionX="contextMenuX"
      :positionY="contextMenuY"
      :pod="pod"
      :asset="contextMenuAsset"
      >
      </AssetsContextMenu>
    </div>
  </div>
</template>

<script>
import MimetypeHelper from "../../../../../components/MimetypeHelper.js"

import AssetsContextMenu from "./AssetsContextMenu.vue"
import UploadFilesDialog from "../../../../../routes/nodes2/components/dialogs/UploadFilesDialog.vue"
import DeleteSelectedAssetsDialog from "./dialogs/DeleteSelectedAssetsDialog.vue";
import DeleteAllAssetsDialog from "./dialogs/DeleteAllAssetsDialog.vue";

import SmallTWDark from '@/assets/images/smalltw-dark.png';
import SmallTWLight from '@/assets/images/smalltw-light.png'

export default {
  name: "AssetsToolbar",
  components: {
    AssetsContextMenu,
    UploadFilesDialog,
    DeleteSelectedAssetsDialog,
    DeleteAllAssetsDialog,
  },
  data: function() {
    return {
      assetMenuIsActive: false,

      showingAssetContextMenu: false,
      contextMenuAsset: null,
      contextMenuX: 0,
      contextMenuY: 0,
    };
  },
  methods: {
    filterByType: function(availableTypes, mimetypeFilter) {
      const JOINTTYPES = [
        this.assetTypes.WEB, this.assetTypes.EXTERNAL, this.assetTypes.OTHER
      ];

      return function(x) {
        if (mimetypeFilter && JOINTTYPES.indexOf(x.type) >= 0 && mimetypeFilter(x)) {
          return true;
        }
        return availableTypes.indexOf(x.type) >= 0;
      };
    },
    filterWithExclusion: function(availableTypes) {
      const DISJOINTTYPES = [
        this.assetTypes.WEB, this.assetTypes.EXTERNAL, this.assetTypes.OTHER
      ];

      return function (x) {
        if (
          DISJOINTTYPES.indexOf(x.type) >= 0 &&
          (
            MimetypeHelper.isVideoAsset(x) ||
            MimetypeHelper.isAudioAsset(x) ||
            MimetypeHelper.isImageAsset(x)
          )
        ) {
          return false;
        }

        return availableTypes.indexOf(x.type) >= 0;
      }
    },
    toggleAssetSelection: function(item) {
      const TOGGLESELECTEDASSET = this.$store.getters.keywords.POD.TOGGLESELECTEDASSET;
      this.$store.commit(TOGGLESELECTEDASSET, item);
    },
    downloadAllAssets() {
      let payload = {
        nodeids: [],
        podids: [this.pod.id],
        assetids: [],
        excludeAssets: false,
      };

      let self = this;

      const GETCURRENTSESSION = this.$store.getters.keywords.AUTH.GETCURRENTSESSION;
      const DOWNLOADTHINGS = this.$store.getters.keywords.COMMON.DOWNLOADTHINGS;

      this.$store.dispatch(GETCURRENTSESSION)
          .then(function(session) {
            payload['id-token'] = session.tokens.idToken.toString();
            return self.$store.dispatch(DOWNLOADTHINGS, payload);
          });
    },
    downloadSelectedAssets() {
      // console.log('this.selectedAssets', this.selectedAssets);
      if (this.selectedAssets.length === 0) {
        return;
      }

      const selectedAssetIds = this.selectedAssets.map((x) => x.id);
      let payload = {
        podids: [this.pod.id],
        assetids: selectedAssetIds,
      };

      let self = this;

      const GETCURRENTSESSION = this.$store.getters.keywords.AUTH.GETCURRENTSESSION;
      const DOWNLOADTHINGS = this.$store.getters.keywords.COMMON.DOWNLOADTHINGS;

      this.$store.dispatch(GETCURRENTSESSION)
          .then(function(session) {
            payload['id-token'] = session.tokens.idToken.toString();
            return self.$store.dispatch(DOWNLOADTHINGS, payload);
          });
    },
    stub(value) {
      console.log(value);
    },
    closeMenu() {
      this.assetMenuIsActive = false;
    },
    contextMenuClick(event, asset) {
      this.showingAssetContextMenu = false;

      this.contextMenuX = event.clientX;
      this.contextMenuY = event.clientY;
      this.contextMenuAsset = asset;

      this.$nextTick(() => {
        this.showingAssetContextMenu = true;
      });
    },
    contextMenuBtnClick(event, asset) {
      this.showingAssetContextMenu = false;

      this.contextMenuX = event.clientX - event.offsetX + event.target.offsetWidth;
      this.contextMenuY = event.clientY - event.offsetY + event.target.offsetHeight;
      this.contextMenuAsset = asset;

      this.$nextTick(() => {
        this.showingAssetContextMenu = true;
      });
    },
  },
  computed: {
    getDarkMode() {
      const GETDARKMODE = this.$store.getters.keywords.MAIN.GETDARKMODE;
      return this.$store.getters[GETDARKMODE];
    },
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    getCurrentNode: function() {
      const GETCURRENTNODE = this.$store.getters.keywords.NODE.GETCURRENTNODE;
      return this.$store.getters[GETCURRENTNODE];
    },
    assetTypes: function() {
      const ASSETTYPES = this.$store.getters.keywords.COMMON.ASSETTYPES;
      return this.$store.getters[ASSETTYPES];
    },
    stackedAssets: function() {
      let result = [];

      if (!this.pod || !this.pod.asset) {
        return result;
      }

      let keys = Object.getOwnPropertyNames(this.pod.asset);

      for(let i = 0; i < keys.length; i++) {
        result = result.concat(this.pod.asset[keys[i]]);
      }

      return result;
    },
    items: function() {
      let mainAssets = [this.assetTypes.RAW];
      let videoAssets = [this.assetTypes.VIDEO];
      let audioAssets = [this.assetTypes.AUDIO];
      let imageAssets = [this.assetTypes.THUMBNAIL, this.assetTypes.LIVEPREVIEW, this.assetTypes.FRAME, this.assetTypes.IMAGE];
      let subsAssets = [this.assetTypes.VTT, this.assetTypes.SRT];
      let otherAssets = [this.assetTypes.EXTERNAL, this.assetTypes.SPEECHTOTEXT, this.assetTypes.OTHER];

      // this.assetTypes.WEB is filtered by mimetype

      let treeArray = [
        { id: "str1", name: "routes.pod.assetRaw", children: this.stackedAssets.filter(this.filterByType(mainAssets))},
        { id: "str2", name: "routes.pod.assetVideo", children: this.stackedAssets.filter(this.filterByType(videoAssets, MimetypeHelper.isVideoAsset))},
        { id: "str3", name: "routes.pod.assetAudio", children: this.stackedAssets.filter(this.filterByType(audioAssets, MimetypeHelper.isAudioAsset))},
        { id: "str4", name: "routes.pod.assetImage", children: this.stackedAssets.filter(this.filterByType(imageAssets, MimetypeHelper.isImageAsset))},
        { id: "str5", name: "routes.pod.assetSubtitle", children: this.stackedAssets.filter(this.filterByType(subsAssets))},
        { id: "str6", name: "routes.pod.assetOther", children: this.stackedAssets.filter(this.filterWithExclusion(otherAssets))},
      ];

      // truncate empty tree items
      return treeArray.filter(x => x.children.length > 0);
    },
    podStates: function() {
      const PODSTATES = this.$store.getters.keywords.COMMON.PODSTATES;
      return this.$store.getters[PODSTATES];
    },
    assetState: function() {
      const states = this.podStates;

      let self = this;
      return function(item) {
        if (item.broken) {
          return self.$t('routes.pod.brokenAsset');
        }
        switch (item.state) {
          case states.ERROR: return self.$t('routes.pod.stateError');
          case states.PENDING: return self.$t('routes.pod.statePending');
          case states.PROCESSING: return self.$t('routes.pod.stateProcessing');
          case states.COMPLETE: return self.$t('routes.pod.stateComplete');
        }
      }
    },
    isCheckboxSelected: function() {
      return function(item) {
        const GETSELECTEDASSETS = this.$store.getters.keywords.POD.GETSELECTEDASSETS;
        const assets = this.$store.getters[GETSELECTEDASSETS];

        return assets.indexOf(item) >= 0;
      };
    },
    isSystemGeneratedFile: function() {
      const systemTypes = [
        this.assetTypes.THUMBNAIL, this.assetTypes.LIVEPREVIEW,
        this.assetTypes.FRAME, this.assetTypes.RAW,
        this.assetTypes.AUDIO, this.assetTypes.WEB,
        this.assetTypes.IMAGE, this.assetTypes.VIDEO,
        // this.assetTypes.VTT, this.assetTypes.SRT,
        this.assetTypes.SPEECHTOTEXT, this.assetTypes.OTHER
      ];
      return function(item) {
        return systemTypes.indexOf(item.type) >= 0;
      }
    },
    selectedAssets: function() {
      const GETSELECTEDASSETS = this.$store.getters.keywords.POD.GETSELECTEDASSETS;
      return this.$store.getters[GETSELECTEDASSETS];
    },
    hasSelectedAssets: function() {
      const GETSELECTEDASSETS = this.$store.getters.keywords.POD.GETSELECTEDASSETS;
      const assets = this.$store.getters[GETSELECTEDASSETS];

      return assets.length > 0;
    },
    getSystemGeneratedIcon: function() {
      if (this.getDarkMode) {
        return SmallTWDark;
      }

      return SmallTWLight;
    },
    isCompletedState: function() {
      return this.pod && this.pod.state === this.podStates.COMPLETE;
    },
  }
}
</script>

<style>
.pod-asset-list-item {
  border-radius: 10px;
  padding: 10px;
}

.theme--dark .pod-asset-list-item {
  background-color: #2f3640;
}

.theme--light .pod-asset-list-item {
  background-color: #e6ecf1;
}
</style>
