<template>
  <div :class="getCssClasses" style="min-width: 150px;">

    <!-- Title of the tree item - the node item -->
    <div
      @click.stop="setActiveNode"
      @contextmenu.prevent="contextmenu"
      class="d-flex justify-space-between"
    >
      <div class="d-flex justify-center align-center">
        <div @click.stop="toggleIsOpen">
          <v-icon v-if="isOpen">mdi-menu-down</v-icon>
          <v-icon v-if="!isOpen">mdi-menu-right</v-icon>
        </div>

        <div v-if="!isLoading">
          <!-- <v-icon v-if="isOpen">mdi-folder-open</v-icon>
          <v-icon v-if="!isOpen">mdi-folder</v-icon> -->
          <v-icon>{{getNodeIcon}}</v-icon>
        </div>

        <v-progress-circular
          v-if="isLoading"
          :size="20"
          color="secondary"
          indeterminate
        ></v-progress-circular>

        <div
          :title="node.name.length > 20 ? node.name : ''"
          :class="{'red--text': node.deletedat}"
          class="ml-1 word-wrap"
          style="white-space: normal; word-break: break-word;"
        >
          {{ node.name | truncateString({ maxLength: 20, startOffset: 7, endOffset: 5 })}}
        </div>
      </div>

      <div
        v-if="countChildNodes"
        class="mr-2 d-flex justify-center align-center"
      >
        <v-chip x-small>
          {{countChildNodes}}
        </v-chip>
      </div>
    </div>

    <!-- The child nodes -->
    <div v-if="isOpen">
      <NodeTreeItem
        v-for="child in getChildrenNodes(node.id)"
        :key="child.id"
        :node="child"
        :sortFunction="sortFunction"
        :openedIds="openedIds"
        @showContextMenu="transmitContextmenuEvent"
      >
      </NodeTreeItem>
    </div>


  </div>
</template>

<script>

export default {
  name: "NodeTreeItem",
  components: {
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    sortFunction: {
      type: Function,
      required: true,
    },
    openedIds: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,


    };
  },
  mounted() {
    if (this.openedIds && this.openedIds.indexOf(this.node.id) >= 0) {
      this.isOpen = true;
      this.isLoading = false;
    }
  },
  watch: {
    openedIds(newIds) {
      if (newIds && newIds.indexOf(this.node.id) >= 0) {
        this.isOpen = true;
        this.isLoading = false;
      }
    }
  },
  methods: {
    async setActiveNode() {
      this.isLoading = true;

      // const VIEWNODE = this.$store.getters.keywords.NODE.VIEWNODE;
      // await this.$store.dispatch(VIEWNODE, this.node.id);

      const routePath = `/node/${this.node.id}`;
      if (this.$route.path !== routePath) {
        this.$router.push(routePath);
      }

      // this.isOpen = !this.isOpen;
      this.isOpen = true;

      this.isLoading = false;
    },
    toggleIsOpen() {
      if (this.isOpen) {
        this.isOpen = false;
      } else {
        this.setActiveNode();
      }
    },
    contextmenu(event) {
      // console.log("Context menu", event)
      // this.showContextMenu = false;
      // this.contextMenuX = event.clientX;
      // this.contextMenuY = event.clientY;
      // this.$nextTick(() => {
      //   this.showContextMenu = true;
      // });

      const eventData = {
        contextMenuNode: this.node,
        contextMenuX: event.clientX,
        contextMenuY: event.clientY,
      };

      this.$emit('showContextMenu', eventData);
    },
    transmitContextmenuEvent(eventData) {
      this.$emit('showContextMenu', eventData);
    },
  },
  computed: {
    getChildrenNodes() {
      return function(parentId) {
        const GETCHILDRENBYID = this.$store.getters.keywords.NODE.GETCHILDRENBYID;
        return this.$store.getters[GETCHILDRENBYID](parentId).slice().sort(this.sortFunction);
      }
    },
    getCurrentNode() {
      const GETCURRENTNODE = this.$store.getters.keywords.NODE.GETCURRENTNODE;
      return this.$store.getters[GETCURRENTNODE];
    },
    countChildNodes() {
      let totalSubnodes = 0;
      if (this.node.subfoldersCount) {
        totalSubnodes += this.node.subfoldersCount;
      }

      const childrenInSore = this.getChildrenNodes(this.node.id).length;
      return Math.max(totalSubnodes, childrenInSore);
    },
    getCssClasses() {
      return {
        'node-tree-item': true,
        'node-tree-item-selected': this.getCurrentNode && (this.node.id == this.getCurrentNode.id)
      }
    },
    getNodeIcon() {
      if (this.node.archived === 1 || this.node.archived === 2) {
        return 'mdi-package-down';
      }

      let iconName = 'mdi-folder';

      switch (this.node.type) {
        // case 1: iconName = 'mdi-folder'; break;
        case 2: iconName = 'mdi-briefcase-clock'; break;
        case 3: iconName = 'tw-office-building'; break;
        case 4: iconName = 'mdi-home-assistant'; break;
        default: iconName = 'mdi-folder'; break;
      }

      if (iconName == 'mdi-folder' && this.isOpen) {
        iconName = 'mdi-folder-open';
      }

      return iconName;
    },
  },
}
</script>

<style>

/*
.node-tree-item {
  padding: 8px 2px 8px 2px;
  border: 1px solid blue;
}
*/

.node-tree-item > div:first-child {
  padding: 8px 0px;
  cursor: pointer;
}


.theme--dark .node-tree-item > div:first-child:hover {
  background-color: rgba(239, 239, 239, 0.1);
  border-radius: 6px;
}
.theme--light .node-tree-item > div:first-child:hover {
  background-color: rgba(17, 17, 17, 0.1);
  border-radius: 6px;
}

.node-tree-item > div:nth-child(2) {
  margin-left: 8px;
}

.node-tree-item-selected {

  border-radius: 6px;
  /* background-color: red; */
  /* background-opacity: 0.8; */
}

.theme--dark .node-tree-item-selected {
  background-color: #2f3640 !important;
}
.theme--light .node-tree-item-selected {
  background-color: #e6ecf1 !important;
}

.node-tree-item-selected > div:first-child{
  color: #3dabff !important;
}
</style>
