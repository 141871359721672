var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pod-thumbnail"},[(_vm.imageType === 'livePreview')?_c('div',{staticClass:"live-preview"},[_c('div',{ref:"livePreview",class:{ 'mx-auto': true, 'image': true, 'compact': _vm.compact },style:({ backgroundImage: `url('${_vm.livePreviewImage}')` }),on:{"!mousemove":function($event){return _vm.moveLivePreview.apply(null, arguments)},"mouseout":_vm.resetLivePreview}},[_c('div',{staticClass:"cursor",staticStyle:{"display":"none"}})])]):_c('div',{class:{
      'mx-auto': true,
      'elevation-1': true,
      'preview-image': true,
      'compact': _vm.compact,
      ..._vm.additionalImageClasses
    },style:({ backgroundImage: `url('${_vm.imageUrl}')` })})])
}
var staticRenderFns = []

export { render, staticRenderFns }