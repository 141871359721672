import Vue from 'vue'

Vue.filter('truncateText', function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + (suffix || '...');
  } else {
    return text;
  }
});

Vue.filter('truncateString', function (text, options) {
  if (text.length > options.maxLength) {
    if (!options?.endOffset) {
      return text.substring(options.start || 0, options.maxLength) + (options.suffix || '...');
    }

    return text.slice(options.start || 0, options.startOffset || 7) + '...' + text.slice(-options.endOffset || 7);
  } else {
    return text;
  }
});
