class ReplaceT {
  constructor() {

  }

  install(Vue) {
    Vue.prototype.$replaceT = function(code, params, fallback) {
      let paramsObject = {};

      if (!code) {
        return fallback;
      }

      if (typeof code !== "string") {
        code = code.toString();
      }

      try {
        if (typeof params === "string") {
          paramsObject = JSON.parse(params);
        } else if (typeof params === "object" && params !== null) {
          paramsObject = params;
        }
      } catch (e) {
        console.log("Oooof", e);
        return fallback;
      }

      let allowedCodes = [
        "AM1", "AM2", "AM3", "AM6", "AM7", "AM8", "AM9", "AM10",
        "AM11", "AM12", "AM13", "AM14", "AM15", "AM16", "AM17", "AM18",
        "AM19", "AM20", "AM21", "AM22", "AM23", "AM24", "AM25", "AM26",
        "AM27", "AM28", "AM29", "AM30", "AM31", "AM32", "AM33", "AM34",
        "AM35", "AM36", "AM37", "AM38", "AM39", "AM40", "AM41", "AM42",
        "AM43", "AM44",

        "NM1", "NM2", "NM3", "NM4", "NM5", "NM6", "NM7", "NM8", "NM9",
        "NM10", "NM11", "NM13", "NM14", "NM15", "NM16", "NM17", "NM18",
        "NM19", "NM20", "NM21", "NM22", "NM23", "NM24", "NM25", "NM26",
        "NM27", "NM28", "NM29", "NM30", "NM31", "NM32", "NM33", "NM34",
        "NM35", "NM36", "NM37", "NM38", "NM39", "NM40", "NM40", "NM41",
        "NM42", "NM43", "NM44", "NM45", "NM46", "NM47", "NM48", "NM49",

        "ERR1", "ERR2", "ERR3", "ERR4", "ERR5", "ERR6", "ERR7", "ERR8",
        "ERR9", "ERR10", "ERR11", "ERR12", "ERR13", "ERR14", "ERR15", "ERR16",
        "ERR17", "ERR18", "ERR19", "ERR20", "ERR21", "ERR22", "ERR23", "ERR24",
        "ERR25", "ERR26", "ERR27", "ERR28", "ERR29", "ERR30", "ERR31", "ERR32",
        "ERR33", "ERR34", "ERR35", "ERR36", "ERR37", "ERR38", "ERR39", "ERR40",
        "ERR41", "ERR42", "ERR43", "ERR44", "ERR45", "ERR46", "ERR47", "ERR48",
        "ERR49", "ERR50", "ERR51", "ERR52", "ERR53", "ERR54", "ERR55", "ERR56",
        "ERR57", "ERR58", "ERR59", "ERR60", "ERR61", "ERR62", "ERR63", "ERR64",
        "ERR65", "ERR66", "ERR67", "ERR68", "ERR69", "ERR70", "ERR71", "ERR72",
        "ERR73", "ERR74", "ERR75", "ERR76", "ERR77", "ERR78", "ERR79", "ERR80",
        "ERR81", "ERR82", "ERR83", "ERR84", "ERR85", "ERR86", "ERR87", "ERR88",
        "ERR89", "ERR90", "ERR91", "ERR92", "ERR93", "ERR94", "ERR95", "ERR96",
        "ERR97", "ERR98", "ERR99", "ERR100", "ERR101", "ERR102", "ERR103", "ERR104",
        "ERR105", "ERR106", "ERR107", "ERR108", "ERR109", "ERR110", "ERR111", "ERR112",
        "ERR113", "ERR114", "ERR115", "ERR116", "ERR117", "ERR118", "ERR119", "ERR120",
        "ERR121", "ERR122", "ERR123", "ERR124", "ERR125", "ERR126", "ERR127", "ERR128",
        "ERR129", "ERR130", "ERR131", "ERR132", "ERR133", "ERR134", "ERR135", "ERR136",
        "ERR137", "ERR138", "ERR139", "ERR140", "ERR141", "ERR142", "ERR143", "ERR144",
        "ERR145", "ERR146", "ERR147", "ERR148", "ERR149", "ERR150", "ERR151", "ERR152",

        "11009", "11002", "11016", "11018",
        "11020", "11021", "11022", "11023", "11024", "11025",
        "11406", "11500", "20003", "20004",

        "reached-users-limit",
        "reached-transcription-limit",
        "reached-storage-limit",
        "reached-translation-limit",
        "reached-bandwidth-limit",
        "reached-rekognition-limit",
        "reached-clientuser-limit",
        "reached-credits-limit",
        "reached-resource-limit",
        "system-error",
        "fileConversionComplete",

        // These are not in use, but we have to keep them for compatibility with the DB records
        "upcoming-users-limit",
        "upcoming-transcription-limit",
        "upcoming-storage-limit",
        "upcoming-translation-limit",
        "upcoming-bandwidth-limit",
        "upcoming-rekognition-limit",
        "upcoming-clientuser-limit",
        "upcoming-credits-limit",

      ];

      if (allowedCodes.indexOf(code) >= 0) {
        return this.$t("backend."+code, paramsObject);
      } else {
        return "Unsupported code: " + code;
      }
    }
  }
}

export default new ReplaceT();
