let isFCPExtension = null;
const knownFCPMessageHandlers = new Set();

const Helper = {
  generatePassword: function(pwdLength = 10) {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const specialChars = "!@#$%^&*";
    const digitChars = "0123456789";

    const randomChars = {
      lowercase: lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)],
      uppercase: uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)],
      special: specialChars[Math.floor(Math.random() * specialChars.length)],
      digit: digitChars[Math.floor(Math.random() * digitChars.length)],
    };

    // Initialize password with characters from each category
    let password = randomChars.lowercase + randomChars.uppercase + randomChars.special + randomChars.digit;

    // Fill remaining characters using combined character set
    const combinedChars = lowercaseChars + uppercaseChars + specialChars + digitChars;
    for (let i = 4; i < pwdLength; i++) {
      password += combinedChars[Math.floor(Math.random() * combinedChars.length)];
    }

    // Optionally shuffle the password to prevent predictability
    password = password.split("").sort(() => Math.random() - 0.5).join("");

    return password;
  },
  isFCPExtension: function() {
    if (isFCPExtension === null) {
      isFCPExtension = 'webkit' in window
        && 'messageHandlers' in window.webkit
        && typeof window.webkit.messageHandlers === 'object'
        && window.webkit.messageHandlers !== null;
    }

    return isFCPExtension;
  },

  isAdobeExtension: function() {
    return !!(window && window.cep);
  },

  getPasswordRules: function(vueInstance, options = { required: true, minLength: true, specialChars: true }) {
    const minPasswordLength = 8;
    const rules = [];

    if (options.required) {
      rules.push((v) => {
        if (typeof v !== 'string' || v.trim() === '') {
          return vueInstance.$t('menus.createUser.passwordRequired');
        }

        return true;
      });
    }

    if (options.minLength) {
      rules.push((v) => {
        if (options.required) {
          if (typeof v !== 'string' || v.trim().length < minPasswordLength) {
            return vueInstance.$t('menus.createUser.passwordLength');
          }
        } else {
          if (typeof v === 'string') {
            const value = v.trim();

            if (value.trim().length > 0 && value.length < minPasswordLength) {
              return vueInstance.$t('menus.createUser.passwordLength');
            }
          }
        }

        return true;
      });
    }

    if (options.specialChars) {
      rules.push((v) => {
        if (options.required) {
          if (typeof v !== 'string' || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*&^%$#@!]).*$/.test(v) === false) {
            return vueInstance.$t('menus.createUser.passwordInvalidChars');
          }
        } else {
          if (typeof v === 'string') {
            const value = v.trim();

            if (value.length > 0 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*&^%$#@!]).*$/.test(value) === false) {
              return vueInstance.$t('menus.createUser.passwordInvalidChars');
            }
          }
        }

        return true;
      });
    }

    return rules;
  },

  isFCPMessageHandler: function (name) {
    if (!this.isFCPExtension()) return false;

    if (knownFCPMessageHandlers.has(name)) {
      return true;
    }

    if (name in window.webkit.messageHandlers &&
      window.webkit.messageHandlers[name] &&
      typeof window.webkit.messageHandlers[name].postMessage === 'function'
    ) {
      knownFCPMessageHandlers.add(name);
      return true;
    }

    return false;
  },

  invokeFCPMessageHandler: function(name, params) {
    if (this.isFCPMessageHandler(name)) window.webkit.messageHandlers[name].postMessage(params);
  },

  isVideo: function(mimetype) {
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
  // https://www.iana.org/assignments/media-types/media-types.xhtml
  // application/mxf https://en.wikipedia.org/wiki/Material_Exchange_Format
    return mimetype.startsWith('video/') || mimetype === 'application/mxf';
  },

  isImage: function(mimetype) {
    return mimetype.startsWith('image/');
  },

  isAudio: function(mimetype) {
    return mimetype.startsWith('audio/');
  },

  getNameValidationRules: function(vueInstance, options = {
    required: true,
    messages: {
      required: 'menus.createUser.firstnameRequired',
      pattern: 'menus.createUser.invalidName',
    },
  }) {
    const rules = [];
    const namePattern = /^(?:[A-Za-z]+\.|[A-Za-z\xC0-\uFFFF]+)(?:[ '-][a-zA-Z\xC0-\uFFFF]+| - [a-zA-Z\xC0-\uFFFF]+(?: [a-zA-Z\xC0-\uFFFF]+)*)*$/u;

    // Check if the name is required
    if (options.required) {
      rules.push((v) => {
        if (typeof v !== 'string' || v.trim() === '') {
          return vueInstance.$t(options.messages.required || 'menus.createUser.firstnameRequired');
        }

        return true;
      });
    }

    // Check if the name is valid based on the pattern
    rules.push((v) => {
      if (!namePattern.test(v)) {
          return vueInstance.$t(options.messages.pattern || 'menus.createUser.invalidName');
      }

      return true;
    });

    return rules;
  },

  getEmailValidationRules: function(vueInstance, options = {
    required: true,
    messages: {
      required: 'menus.createUser.emailRequired',
      pattern: 'menus.createUser.emailNotValid',
    },
  }) {
    const rules = [];
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Check if the email is required
    if (options.required) {
      rules.push((v) => {
        if (typeof v !== 'string' || v.trim() === '') {
          return vueInstance.$t(options.messages.required || 'menus.createUser.emailRequired');
        }

        return true;
      });
    }

    // Check if the email is valid based on the pattern
    rules.push((v) => {
      if (!emailPattern.test(v)) {
        return vueInstance.$t(options.messages.pattern || 'menus.createUser.emailNotValid');
      }

      return true;
    });

    return rules;
  },
}


export default Helper;
