<template>
  <div id="top-bar-component">
    <v-system-bar window class="first-top-bar px-xs-2  px-sm-5 px-md-10">

        <!-- TODO: Show this button only on the /nodes/:id route -->
        <v-btn
          v-if="$vuetify.breakpoint.xsOnly"
          @click="toggleSidemenu()"
          class="mx-1"
          height="48px"
          width="48px"
          elevation=0
          large icon tile
        >
          <v-icon class="mr-0" size="28px">mdi-view-split-horizontal</v-icon>
        </v-btn>

        <router-link v-bind:to="getHeaderLink" class="main-logo mx-1">
          <img v-if="this.getDarkMode" src="../../../../assets/images/tw-main-logo-dark.png" />
          <img v-if="!this.getDarkMode" src="../../../../assets/images/tw-main-logo-light.png" />
        </router-link>

        <v-spacer></v-spacer>

        <breadcrumb v-if="!showingBackToFilesButton && $vuetify.breakpoint.smAndUp"></breadcrumb>
        <div v-if="showingBackToFilesButton">
          <v-btn
          v-if="currentNode"
          :to="'/node/' + currentNode.id"
          color="primary"
          class="mx-1"
          elevation=0>
            <v-icon>mdi-keyboard-backspace</v-icon>
            {{$t('layouts.main.backToFiles')}}
          </v-btn>
        </div>

        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          color="primary"
          class="mx-1"
          elevation=0
          to="/console/index"
          data-cy="topbar-managementConsole-button"
        >
          <v-icon>mdi-tune-vertical</v-icon>
          {{$t('layouts.main.managementConsole')}}
        </v-btn>


        <NotificationsMenu>
        </NotificationsMenu>


        <BeeUserMenu :forceManagementConsole="false">
        </BeeUserMenu>

    </v-system-bar>

    <!-- The breadcrumb component on the responsive view -->
    <div v-if="$vuetify.breakpoint.xsOnly" class="second-top-bar px-2" style="">
      <breadcrumb v-if="!showingBackToFilesButton"></breadcrumb>

      <div v-if="showingBackToFilesButton" class="text-center">
        <v-btn
        v-if="currentNode"
        :to="'/node/' + currentNode.id"
        color="primary"
        class="mx-1 mt-1"
        x-small
        elevation=0>
          <v-icon>mdi-keyboard-backspace</v-icon>
          {{$t('layouts.main.backToFiles')}}
        </v-btn>
      </div>

    </div>

  </div>
</template>

<script>
import Breadcrumb from './Breadcrumb.vue'
import BeeUserMenu from './BeeUserMenu.vue';
import NotificationsMenu from './notifications/NotificationsMenu.vue'
// import SupportForms from './support/SupportForms'

export default {
  name: "TopBar",
  components: {
    Breadcrumb,
    BeeUserMenu,
    NotificationsMenu,
    // FilterForm,
    // SearchForm,
    // SupportForms,
  },
  props: {
  },
  data: function() {
    return {
    };
  },
  mounted: function() {
  },
  methods: {
    toggleSidemenu() {
      const TOGGLESIDEMENU = this.$store.getters.keywords.MAIN.TOGGLESIDEMENU;
      this.$store.commit(TOGGLESIDEMENU);
    },
    // toggleArchiveView: function() {
    //   const SETARCHIVEDFILTER = this.$store.getters.keywords.FILTER.SETARCHIVEDFILTER;
    //   this.$store.commit(SETARCHIVEDFILTER, !this.getArchivedFilter);
    // },
    // toggleNodeView: function() {
    //   const TOGGLENODEVIEW = this.$store.getters.keywords.NODE.TOGGLENODEVIEW;
    //   this.$store.dispatch(TOGGLENODEVIEW);
    // },
  },
  computed: {
    getDarkMode() {
      const GETDARKMODE = this.$store.getters.keywords.MAIN.GETDARKMODE;
      return this.$store.getters[GETDARKMODE];
    },
    // newNotification: function() {
    //   const NEWNOTIFICATION = this.$store.getters.keywords.NOTIFICATION.NEWNOTIFICATION;
    //   return this.$store.getters[NEWNOTIFICATION];
    // },
    // notificationColor: function() {
    //   if (this.newNotification == 2) {
    //     return "error ";
    //   } else if (this.newNotification) {
    //     return "primary !important";
    //   } else {
    //     return "#FFFFFF";
    //   }
    // },
    // accessPoints: function() {
    //   const ACCESSPOINTS = this.$store.getters.keywords.NODE.ACCESSPOINTS;
    //   return this.$store.getters[ACCESSPOINTS];
    // },
    currentNode: function() {
      const CURRENTNODE = this.$store.getters.keywords.NODE.CURRENTNODE;
      return this.$store.getters[CURRENTNODE];
    },
    getRootNodeId() {
      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
      return this.$store.getters[ROOTNODEID];
    },
    getHeaderLink: function() {
      if (this.getRootNodeId) {
        return '/node/' + this.getRootNodeId;
      }

      return '/';
    },
    showingBackToFilesButton() {
      return this.$route.path.startsWith('/recycle-bin');
    },
    // getArchivedFilter: function() {
    //   const GETARCHIVEDFILTER = this.$store.getters.keywords.FILTER.GETARCHIVEDFILTER;
    //   return this.$store.getters[GETARCHIVEDFILTER];
    // },
    // isArchivedDisabled: function() {
    //   return this.currentNode && this.currentNode.archived !== 0;
    // },
    // getNodeView: function() {
    //   const GETNODEVIEW = this.$store.getters.keywords.NODE.GETNODEVIEW;
    //   return this.$store.getters[GETNODEVIEW];
    // },
    // showingNodeViewToggle: function() {
    //   return  this.$route.path.startsWith('/node') ||
    //           this.$route.path.startsWith('/search') ||
    //           this.$route.path.startsWith('/archive');
    // },
    // areButtonsShown: function() {
    //   if (this.windowSize >= 960) {
    //     return true;
    //   }

    //   return this.smallScreenToggle;
    // }
  }
}
</script>

<style>
  #top-bar-component {
    top: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
    z-index: 3;
  }

  .v-system-bar {
    font-size: 14px !important;
  }

  .main-logo {
    line-height: 0px;
  }

  .main-logo > img {

    width: 150px;
    height: 35px;
    /* margin-top: 8px; */
  }

  @media only screen and (max-width: 960px) {
    .main-logo > img {
      width: 110px;
      height: 24px;
      margin-top: 0px;
    }
  }

  @media only screen and (max-width: 600px) {
    .main-logo > img {
      width: 90px;
      height: 20px;
      margin-top: 3px;
    }
  }

  .first-top-bar {
    min-height: 76px !important;
  }

  @media only screen and (max-width: 600px) {
    .first-top-bar {
      min-height: 56px !important;
    }
  }

  .first-top-bar {
    background-color: var(--tw-fg-color-primary) !important;
  }

  .theme--light.v-system-bar .v-icon {
    color: #131313;
  }

  .second-top-bar {
    height: 30px;
    background-color: var(--tw-bg-color-primary) !important;
  }
</style>
