<template>
  <div v-show="assets.length > 0" class="scrollable pa-0 ma-0 subtitle-assets-list" data-cy="podMenu-subtitles-list">
    <v-row v-for="asset in assets" :key="asset.id" dense no-gutters data-cy="podMenu-subtitles-assetFile">
      <v-col cols="8" data-cy="podMenu-subtitles-assetName">
        <v-radio-group v-model="selectedAssetId" hide-details >
          <v-radio :label="asset.name" :value="asset.id" ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="2" class="d-flex justify-center align-center text-uppercase">
        <span style="margin-top:5px;">
          {{ getTypeText(asset.type) }}
        </span>
      </v-col>
      <v-col cols="2" class="d-flex justify-center align-center" style="padding-top: 3px;">
        <v-menu offset-y ref="menuSubtitleAssets">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" data-cy="podMenu-subtitles-assetActionsButton">mdi-dots-vertical</v-icon>
          </template>

          <v-list data-cy="podFiles-subtitles-assetActions">
            <RenameAssetDialog
              v-if="$canControlNodeAndPod()"
              v-bind:pod="pod"
              v-bind:asset="asset"
              data-cy="podFiles-subtitles-renameAsset"
            >
              <v-list-item v-on:click="closeSubtitleAssetsMenu()">
                <v-list-item-title>
                  <v-icon>mdi-rename-box</v-icon>
                  {{ $t('common.rename') }}
                </v-list-item-title>
              </v-list-item>
            </RenameAssetDialog>

            <DeletePodDialog
              v-if="$canControlNodeAndPod()"
              v-bind:pod="pod"
              v-bind:asset="asset"
              data-cy="podFiles-subtitles-deleteAsset"
            >
              <v-list-item v-on:click="closeSubtitleAssetsMenu()">
                <v-list-item-title>
                  <v-icon>mdi-trash-can-outline</v-icon>
                  {{ $t('common.delete') }}
                </v-list-item-title>
              </v-list-item>
            </DeletePodDialog>


            <TranslateSubtitleAssetDialog
                v-if="$canRunPaidService() && (isVideoPod || isAudioPod)"
                v-bind:asset="asset"
                @closeMenu="closeSubtitleAssetsMenu"
                data-cy="podFiles-subtitles-translateAsset"
            >
              <v-list-item v-on:click="closeSubtitleAssetsMenu()">
                <v-list-item-title>
                  <v-icon>mdi-translate-variant</v-icon>
                  {{ $t('routes.pod.menuTranslateSubtitles.translate') }}
                </v-list-item-title>
              </v-list-item>
            </TranslateSubtitleAssetDialog>

            <ImprintSubtitleDialog
                v-if="$canRunFreeService() && isVideoPod"
                v-bind:asset="asset"
                @closeMenu="closeSubtitleAssetsMenu"
                data-cy="podFiles-subtitles-imprintAsset"
            >
              <v-list-item v-on:click="closeSubtitleAssetsMenu()">
                <v-list-item-title>
                  <v-icon>mdi-movie-open-plus-outline</v-icon>
                  {{ $t('routes.pod.menuImprintSubtitles.imprintSubtitles') }}
                </v-list-item-title>
              </v-list-item>
            </ImprintSubtitleDialog>

          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TranslateSubtitleAssetDialog from './dialogs/TranslateSubtitleAssetDialog.vue'
import ImprintSubtitleDialog from './dialogs/ImprintSubtitleDialog.vue'

import RenameAssetDialog from '../assetsToolbar/dialogs/RenameAssetDialog.vue'
import DeletePodDialog from '../../../../../routes/nodes2/components/dialogs/DeletePodDialog.vue'

export default {
  name: "PodSubtitleAssetsList",
  components: {
    TranslateSubtitleAssetDialog,
    ImprintSubtitleDialog,

    RenameAssetDialog,
    DeletePodDialog,
  },
  props: {
    assets: Array,
  },
  data: function() {
    return {
      selectedAssetId: null,
      previousSelectedAssetId: null,
      disableEditing: null,
      timeoutHandler: null,
    };
  },
  watch: {
    selectedAssetId: function(value) {
      if (this.timeoutHandler !== null) {
        clearTimeout(this.timeoutHandler);
      }

      if (this.previousSelectedAssetId !== value) {
        this.timeoutHandler = setTimeout(() => {
          this.setEditItem(value);
          this.previousSelectedAssetId = value;
        }, 200);
      }
    },
  },
  mounted() {
    // Adds event listener to close menu when scrolling
    const nav = document.querySelector('.v-navigation-drawer__content');
    nav && nav.addEventListener('scroll', this.closeSubtitleAssetsMenu);

    if (this.selectedSubtitleAsset) {
      this.selectedAssetId = this.selectedSubtitleAsset.id;
    }
  },
  beforeDestroy() {
    // Removes event listener to close menu when scrolling
    const nav = document.querySelector('.v-navigation-drawer__content');
    nav && nav.removeEventListener('scroll', this.closeSubtitleAssetsMenu);
  },
  methods: {
    stub: function () {
      this.showingMenu = false;
    },
    setEditItem: function(item) {
      if (this.disableEditing) {
        console.log('Editing is disabled');
        return;
      }

      this.$emit('setAssetForEditing', item);
    },
    getTypeText(type) {
      switch (type) {
        case 6:
          return 'vtt';
        case 7:
          return 'srt';
        default:
          return '';
      }
    },
    closeSubtitleAssetsMenu() {
      if (this.$refs.menuSubtitleAssets && this.$refs.menuSubtitleAssets.length > 0) {
        for (let i = 0; i < this.$refs.menuSubtitleAssets.length; i++) {
          this.$refs.menuSubtitleAssets[i].isVisible = false;
        }
      }
    },
  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    isVideoPod: function() {
      const ISVIDEOPOD = this.$store.getters.keywords.POD.ISVIDEOPOD;
      return this.$store.getters[ISVIDEOPOD];
    },
    isAudioPod: function() {
      const ISAUDIOPOD = this.$store.getters.keywords.POD.ISAUDIOPOD;
      return this.$store.getters[ISAUDIOPOD];
    },
    selectedSubtitleAsset: function () {
      const GETSELECTEDASSET = this.$store.getters.keywords.SUBTITLE.GETSELECTEDASSET;
      return this.$store.getters[GETSELECTEDASSET];
    },
  },
}
</script>
