<template>
  <div class="pod-thumbnail">
    <div
      v-if="imageType === 'livePreview'"
      class="live-preview"
    >
      <div
        ref="livePreview"
        :class="{ 'mx-auto': true, 'image': true, 'compact': compact }"
        @mousemove.capture="moveLivePreview"
        @mouseout="resetLivePreview"
        :style="{ backgroundImage: `url('${livePreviewImage}')` }"
      >
        <div class="cursor" style="display: none"/>
      </div>
    </div>
    <div
      v-else
      :class="{
        'mx-auto': true,
        'elevation-1': true,
        'preview-image': true,
        'compact': compact,
        ...additionalImageClasses
      }"
      :style="{ backgroundImage: `url('${imageUrl}')` }"
    />
  </div>
</template>

<script>
import twSpinnerDark from '@/assets/images/tw-spinner-dark.gif';
import twSpinnerLight from '@/assets/images/tw-spinner-light.gif';
import twShutterDark from '@/assets/images/tw-shutter-dark.gif';
import twShutterLight from '@/assets/images/tw-shutter-light.gif';
import archiveThumbnailDark from '@/assets/images/archive-thumbnail-dark.png';
import archiveThumbnailLight from '@/assets/images/archive-thumbnail-light.png';
import imageBroken from '@/assets/images/image-broken.png';
import noThumbnailImage from '@/assets/images/no-thumbnail-small.png';
import audioThumbnail from '@/assets/images/audio-thumbnail-small.png';
import MimetypeHelper from '@/components/MimetypeHelper';

export default {
  name: 'PodThumbnail',
  props: {
    item: {
      type: Object,
      required: true
    },
    compact: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      livePreviewImage: '',
    };
  },
  mounted() {
    if (this.imageType === 'livePreview') {
      this.refreshLivePreviewImage();
    }
  },
  methods: {
    moveLivePreview: function (e) {
      const x = e.offsetX;
      const totalFrames = 140;
      const pixelsPerFrame = this.boxSize / totalFrames;

      let currentFrame = Math.floor(x / pixelsPerFrame);
      if (currentFrame >= totalFrames) {
        currentFrame = totalFrames - 1;
      } else if (currentFrame < 0) {
        currentFrame = 0;
      }

      const positionX = currentFrame * this.boxSize;
      this.$refs.livePreview.style['background-position-x'] = `${-positionX}px`;
      this.$refs.livePreview.firstChild.style['left'] = `${x}px`;
      this.$refs.livePreview.firstChild.style['display'] = '';
    },
    resetLivePreview: function () {
      const totalFrames = 140;
      const currentFrame = Math.ceil(totalFrames / 2);

      this.$refs.livePreview.style['background-position-x'] = `${-currentFrame * this.boxSize}px`;
      this.$refs.livePreview.firstChild.style['display'] = 'none';
    },
    refreshLivePreviewImage: function() {
      if (!this.isBroken && this.livePreviewAsset) {
        this.livePreviewImage = this.livePreviewAsset.url;
        this.resetLivePreview();
      }
    },
  },
  watch: {
    imageType(newType) {
      if (newType === 'livePreview') {
        this.$nextTick(() => this.refreshLivePreviewImage());
      }
    }
  },
  computed: {
    boxSize() {
      return this.compact ? 140 : 200;
    },
    twSpinner() {
      return this.getDarkMode ? twSpinnerDark : twSpinnerLight;
    },
    twShutter() {
      return this.getDarkMode ? twShutterDark : twShutterLight;
    },
    archiveThumbnail() {
      return this.getDarkMode ? archiveThumbnailDark : archiveThumbnailLight;
    },
    isBroken() {
      if (!this.item.asset) {
        return false;
      }

      for (const assetKey in this.item.asset) {
        for (const asset of this.item.asset[assetKey]) {
          if (asset.broken) return true;
        }
      }

      return false;
    },
    isAudioItem() {
      return MimetypeHelper.isMimetypeAudio(this.item);
    },
    isArchiveItem() {
      return MimetypeHelper.isMimetypeArchive(this.item);
    },
    podState() {
      const PODSTATES = this.$store.getters.keywords.COMMON.PODSTATES;
      return this.$store.getters[PODSTATES];
    },
    assetState() {
      const ASSETSTATES = this.$store.getters.keywords.COMMON.ASSETSTATES;
      return this.$store.getters[ASSETSTATES];
    },
    livePreviewAsset() {
      if (this.item.asset && this.item.asset.livepreview && this.item.asset.livepreview.length > 0) {
        return this.item.asset.livepreview.find((val) => val.state === this.assetState.COMPLETE);
      }

      return null;
    },
    thumbnailAsset() {
      if (this.item.asset && this.item.asset.thumbnail && this.item.asset.thumbnail.length > 0) {
        return this.item.asset.thumbnail.find((val) => val.state === this.assetState.COMPLETE);
      }

      return null;
    },
    imageUrl() {
      switch (this.imageType) {
        case 'broken':
          return imageBroken;
        case 'spinner':
          return this.twSpinner;
        case 'shutter':
          return this.twShutter;
        case 'livePreview':
          return this.livePreviewAsset.url;
        case 'thumbnail':
          return this.thumbnailAsset.url;
        case 'audio':
          return audioThumbnail;
        case 'archive':
          return this.archiveThumbnail;
        default:
          return noThumbnailImage;
      }
    },
    imageType() {
      if (this.isBroken) {
        return 'broken';
      }

      switch (this.item.state) {
        case this.podState.ERROR:
          return 'imageBroken';
        case this.podState.PENDING:
          return 'spinner';
        case this.podState.PROCESSING:
          return 'shutter'
        case this.podState.COMPLETE:
          if (this.livePreviewAsset) {
            return 'livePreview';
          } else if (this.thumbnailAsset) {
            return 'thumbnail';
          } else if (this.isAudioItem) {
            return 'audio';
          } else if (this.isArchiveItem) {
            return 'archive';
          }

          return 'noThumbnail';
        default:
          return 'noThumbnail';
      }
    },
    additionalImageClasses() {
      if (this.isBroken || this.isArchiveItem || ['spinner', 'shutter'].includes(this.imageType)) {
        return {
          'background-contain': true,
        };
      }

      return {};
    }
  }
}
</script>

<style scoped>
.pod-thumbnail {
  --image-width: 200px;
  --image-height: 112px;
  --compact-image-width: 140px;
  --compact-image-height: 80px;
}

.pod-thumbnail .live-preview {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-image: url("@/assets/images/tw-transparent.png");
}

.pod-thumbnail .live-preview > .image {
  cursor: ew-resize;
  width: var(--image-width);
  height: var(--image-height);
  background-size: 28000px var(--image-height);
}

.pod-thumbnail .live-preview > .image > .cursor {
  position: relative;
  height: 100%;
  width: 0;
  border: 1px solid #ff0000;
  pointer-events: none;
}

.pod-thumbnail .live-preview > .image.compact {
  width: var(--compact-image-width);
  height: var(--compact-image-height);
  background-size: 19600px var(--compact-image-height);
}

.pod-thumbnail .preview-image {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: var(--image-width);
  height: var(--image-height);
}

.pod-thumbnail .preview-image.compact {
  width: var(--compact-image-width);
  height: var(--compact-image-height);
}

.pod-thumbnail .background-contain {
  background-size: contain;
}
</style>
