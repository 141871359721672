<template>
  <div id="quick-pod-switcher" :style="cssStyles">
    <v-overlay
      :dark="this.$vuetify.theme.dark"
      :light="!this.$vuetify.theme.dark"
      :value="isVisible"
      opacity="0.90"
      absolute
    >
      <div class="expand-vw">
        <div v-if="pods.length > 0" class="swiper-container">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div
                v-for="(pod, idx) in pods"
                :key="idx"
                :class="{ 'swiper-slide': true, 'current-pod': pod.id === viewPodId }"
                @click.stop="switchToPod(pod.id)"
              >
                <PodThumbnail :item="pod" />
                <div class="swiper-slide-pod-name">{{ pod.name }}</div>
              </div>
            </div>
          </div>
          <div class="swiper-scrollbar"></div>
          <div class="swiper-button-prev">
            <v-icon x-large>mdi-chevron-left</v-icon>
          </div>
          <div class="swiper-button-next">
            <v-icon x-large>mdi-chevron-right</v-icon>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import PodThumbnail from '@/layouts2/components/PodThumbnail.vue';

export default {
  name: 'QuickPodSwitcher',
  components: { PodThumbnail },
  props: {
    pods: {
      type: Array,
      required: true,
    },
    viewPodId: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      swiperInstance: null,
      isInteractingWithSlider: false,
      clearSliderInteractionFlagTimeout: null,
    };
  },
  emits: ['switchToPod', 'requestHide'],
  computed: {
    cssStyles() {
      return {
        top: this.$vuetify.breakpoint.smAndUp ? '76px' : '56px',
        visibility: this.isVisible ? 'visible' : 'hidden',
      };
    },
    isVisible() {
      return this.$props.visible;
    }
  },
  beforeMount() {
    window.addEventListener('keyup', this.handleEscapeKeyEvent);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleEscapeKeyEvent);
  },
  methods: {
    switchToPod(podId) {
      this.$emit('switchToPod', podId);
    },
    handleClickOutsideSwiper(event) {
      if (!this.isInteractingWithSlider && this.isVisible && !event.target.matches('.swiper-container *')) {
        this.$emit('requestHide');
      }
    },
    handleEscapeKeyEvent(event) {
      if ((event.key === 'Escape' || event.keyCode === 27) && this.isVisible) {
        this.$emit('requestHide');
      }
    },
    handleSliderInteractionStarted() {
      if (this.clearSliderInteractionFlagTimeout) {
        clearTimeout(this.clearSliderInteractionFlagTimeout);
      }

      this.isInteractingWithSlider = true;
    },
    handleSliderInteractionEnded() {
      this.clearSliderInteractionFlagTimeout = setTimeout(() => {
        this.isInteractingWithSlider = false;
      }, 50);
    },
    initSwiper() {
      const baseMaxVisibleSlides = 5;
      const totalSlides = this.pods.length;

      this.swiperInstance = new Swiper('#quick-pod-switcher .swiper', {
        direction: 'horizontal',
        slideActiveClass: 'active',
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
          hide: false,
          snapOnRelease: true,
        },
        breakpoints: {
          1280: {
            slidesPerView: Math.min(baseMaxVisibleSlides, totalSlides),
          },
          1024: {
            slidesPerView: Math.min(baseMaxVisibleSlides - 1, totalSlides),
          },
          800: {
            slidesPerView: Math.min(baseMaxVisibleSlides - 2, totalSlides),
          },
          568: {
            slidesPerView: Math.min(baseMaxVisibleSlides - 3, totalSlides),
          }
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        mousewheel: {
          enabled: true,
          eventsTarget: '.swiper-container',
        },
        centeredSlides: true,
        centerInsufficientSlides: true,
        initialSlide: this.pods.findIndex((pod) => pod.id === this.viewPodId),
      });

      // Prevent the users from closing the switcher if the mouse button was released/pointing device was lifted
      // outside the slider.
      this.swiperInstance.on('touchStart', this.handleSliderInteractionStarted);
      this.swiperInstance.on('scrollbarDragStart', this.handleSliderInteractionStarted);

      this.swiperInstance.on('touchEnd', this.handleSliderInteractionEnded);
      this.swiperInstance.on('scrollbarDragEnd', this.handleSliderInteractionEnded);

      this.swiperInstance.on('keyPress', (swiper, keyCode) => {
        const pod = this.pods[swiper.activeIndex];
        if (keyCode === 13 && pod.id !== this.viewPodId) {
          this.switchToPod(pod.id);
        }
      });
    },
  },
  watch: {
    isVisible(val) {
      if (val) {
        setTimeout(() => {
          this.initSwiper();
          window.addEventListener('click', this.handleClickOutsideSwiper);
        }, 0)
      } else if (this.swiperInstance) {
        this.swiperInstance.destroy();
        window.removeEventListener('click', this.handleClickOutsideSwiper);
      }
    }
  }
}
</script>

<style scoped>
#quick-pod-switcher {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 50;
}

#quick-pod-switcher .v-overlay {
  align-items: flex-start;
}

#quick-pod-switcher .expand-vw {
  width: 100vw;
}

.swiper-container {
  max-width: 1180px;
  position: relative;
  margin: 15px auto 0;
  background-color: var(--tw-bg-color-primary);
  border-radius: 6px;
  padding: 10px 5px;
}

.swiper-slide {
  background-color: var(--tw-fg-color-primary);
  padding: 12px;
  margin: 5px;
  border-radius: 6px;
  width: 200px;
  max-width: 200px;
  cursor: pointer;
  user-select: none;
}

.swiper-slide.current-pod {
  background-color: var(--tw-fg-color-active);
}

.swiper-slide.active {
  border: 2px solid var(--tw-text-color-primary);
}

.swiper-slide .swiper-slide-pod-name {
  color: var(--tw-text-color-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 5px;
}

.swiper-slide .swiper-slide-pod-name:hover {
  text-decoration: underline;
}

.swiper-slide .listview-pod-thumbnail {
  height: 112px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../../assets/images/no-thumbnail-small.png");
}

.swiper-button-prev,
.swiper-button-next {
  --swiper-navigation-sides-offset: -40px;
  --swiper-navigation-top-offset: 50%;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
}

.swiper-button-prev i,
.swiper-button-next i {
  color: var(--tw-primary);
}

.swiper-scrollbar {
  --swiper-scrollbar-bottom: 4px;
  --swiper-scrollbar-drag-bg-color: var(--tw-scrollbar-fg-color);
}

@media screen and (max-width: 1279px) {
  .swiper-container {
    max-width: 945px;
  }
}

@media screen and (max-width: 1023px) {
  .swiper-container {
    max-width: 711px;
  }
}

@media screen and (max-width: 799px) {
  .swiper-container {
    max-width: 478px;
  }
}

@media screen and (max-width: 567px) {
  .swiper-container {
    max-width: 245px;
  }
}
</style>
